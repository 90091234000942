<div class="tw-w-full">
  <div class="tw-flex tw-min-h-svh">
    <div
      class="tw-w-full md:tw-w-1/2 tw-flex tw-justify-center tw-items-center tw-border-r tw-border-b tw-bg-gray-100 tw-p-12"
    >
      <img src="/assets/logo/logo-v2.svg" class="tw-w-1/2" />
    </div>
    <div class="tw-relative tw-flex tw-flex-col tw-justify-center tw-w-full md:tw-w-1/2 tw-p-12 tw-bg-white">
      <div class="tw-max-w-[750px] tw-mx-auto tw-border tw-rounded tw-p-4 tw-bg-gray-100">
        <sb-onboarding-success></sb-onboarding-success>
      </div>
    </div>
  </div>
</div>
