<form class="tw-flex tw-grow tw-relative">
  @if (!showEnterIndicator && !searching) {
    <fa-icon
      [icon]="['fal', 'magnifying-glass']"
      size="xs"
      class="tw-absolute tw-top-1/2 tw-right-3 -tw-translate-y-1/2 tw-text-gray-500"
    ></fa-icon>
  }
  @if (showEnterIndicator && !searching) {
    <fa-icon
      [icon]="['fal', 'arrow-turn-down-left']"
      size="xs"
      class="tw-absolute tw-top-5 tw-right-3 -tw-translate-y-1/2 tw-text-gray-500"
    ></fa-icon>
  }
  @if (searching) {
    <fa-icon
      animation="spin"
      animation="spin"
      [icon]="['fal', 'spinner-third']"
      size="xs"
      class="tw-absolute tw-top-1/2 tw-right-3 -tw-translate-y-1/2 tw-text-gray-500"
    ></fa-icon>
  }

  <input
    type="text"
    class="tw-text-base tw-bg-gray-100 tw-h-9 tw-rounded-md tw-w-full tw-px-3 tw-py-2.5 tw-transition-all tw-outline-none"
    placeholder="Søg..."
    aria-label="Number"
    matInput
    [formControl]="searchControl"
    [matAutocomplete]="auto"
    [class.corners]="searchOpen || searchNoResults"
    (blur)="searchOpen = searchNoResults || false"
    (keydown.enter)="search()"
  />
</form>

<mat-autocomplete
  #auto="matAutocomplete"
  (opened)="searchOpen = true"
  (closed)="searchOpen = false"
  (optionSelected)="onOptionSelected($event.option.value.id)"
  [displayWith]="displayWith"
>
  @for (order of searchResults; track order) {
    <mat-option
      [value]="order"
      class="[&>span]:tw-flex [&>span]:tw-flex-wrap [&>span]:tw-w-full [&>span]:tw-justify-between"
    >
      <span class="tw-font-bold">{{ order.attributes.web_no }}</span>
      <span sb-badge="basic" class="">{{ order.attributes.store_id | storeNameById | middleEllipsis }}</span>
    </mat-option>
  } @empty {
    @if (searchNoResults) {
      <mat-option disabled>Ingen resultater</mat-option>
    }
  }
</mat-autocomplete>
