import { DataObject, Subscription, SubscriptionPlans } from '@app/_shared/interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import { InstallQueryParams } from '../oauth/install/install.resolver';
import { DineroOrganization, OAuthActions, OnboardingStore } from '../oauth/install/install.service';
import * as DineroActions from './dinero.actions';
import { IntegrationCategory, IntegrationPlatform } from './integrations.service';
import * as OnboardingActions from './onboarding.actions';
import * as ShopifyActions from './shopify.actions';

export interface Flow {
  done: boolean;
  storeCreated: boolean;
  integrationCreated: boolean;
  integrationUpdated: boolean;
}

export interface Alert {
  href: string;
  hrefLabel: string;
  message: string;
}

export interface OAuthState {
  action: OAuthActions;
  category: IntegrationCategory;
  platform: IntegrationPlatform;
  storeId: number | null;
  apiIntegrationId: number | null;
  institutionId?: string | null;
}

export interface State {
  loading: boolean;
  flow: Flow;
  alert: Alert;
  store_id: number;
  ssl: string;
  url: string;
  oAuthState: OAuthState;
  stores: OnboardingStore[];
  queryParams: InstallQueryParams;
  plans: DataObject<SubscriptionPlans>[];
  subscriptions: DataObject<Subscription>[];
  organizations: DineroOrganization[];
}

export const initialState: State = {
  loading: false,
  flow: {
    done: false,
    storeCreated: false,
    integrationCreated: false,
    integrationUpdated: false,
  },
  alert: {
    href: null,
    hrefLabel: null,
    message: null,
  },
  store_id: null,
  ssl: null,
  url: null,
  queryParams: {},
  oAuthState: {
    action: null,
    category: null,
    platform: null,
    storeId: null,
    apiIntegrationId: null,
  },
  stores: [],
  plans: [],
  subscriptions: [],
  organizations: [],
};

const featureReducer = createReducer(
  initialState,
  on(OnboardingActions.getOAuthState, (state) => ({ ...state, loading: true })),
  on(
    OnboardingActions.setQueryParams,
    (state, { code, hmac, host, shop, state: stateParam, timestamp, token, ref, error }) => ({
      ...state,
      queryParams: {
        code,
        hmac,
        host,
        shop,
        state: stateParam,
        timestamp,
        token,
        ref,
        error,
      },
    }),
  ),
  on(
    OnboardingActions.setOAuthState,
    (state, { action, category, platform, apiIntegrationId, storeId, institutionId }) => ({
      ...state,
      oAuthState: { action, category, platform, apiIntegrationId, storeId, institutionId },
      loading:
        platform === 'dinero' ? true : action === OAuthActions.APP_RE_INSTALL || action === OAuthActions.RE_INSTALL,
    }),
  ),
  on(OnboardingActions.setIntegrationPlatform, (state, { integrationPlatform }) => ({
    ...state,
    oAuthState: { ...state.oAuthState, platform: integrationPlatform },
  })),
  on(OnboardingActions.setIntegrationId, (state, { integrationId }) => ({
    ...state,
    oAuthState: { ...state.oAuthState, apiIntegrationId: integrationId },
  })),
  on(OnboardingActions.addStores, (state, { stores }) => ({
    ...state,
    loading: false,
    stores: [...state.stores, ...stores],
  })),
  on(OnboardingActions.resetStores, (state) => ({ ...state, stores: [] })),
  on(OnboardingActions.getPlans, (state) => ({ ...state, loading: true })),
  on(OnboardingActions.setPlans, (state, { plans }) => ({ ...state, plans, loading: false })),
  on(OnboardingActions.createStoreSuccess, (state, { storeId }) => ({
    ...state,
    store_id: storeId,
    flow: { ...state.flow, storeCreated: true },
  })),
  on(OnboardingActions.createIntegrationSuccess, (state) => ({
    ...state,
    flow: { ...state.flow, integrationCreated: true },
  })),
  on(ShopifyActions.updateShopifyIntegrationSuccess, (state) => ({
    ...state,
    flow: { ...state.flow, integrationUpdated: true },
  })),
  on(DineroActions.updateDineroIntegrationSuccess, (state) => ({
    ...state,
    flow: { ...state.flow, integrationUpdated: true },
  })),
  on(OnboardingActions.createStore, (state) => ({ ...state, loading: true, alert: { ...initialState.alert } })),
  on(OnboardingActions.createIntegration, (state) => ({ ...state, loading: true })),
  on(OnboardingActions.createIntegrationSuccess, (state) => ({
    ...state,
    loading: state.oAuthState.platform === 'dinero',
  })),
  on(OnboardingActions.createStoreFailure, (state, error) => ({
    ...state,
    loading: false,
    alert: { message: 'Noget gik desværre galt: ' + error.error.message, href: null, hrefLabel: null },
  })),
  on(OnboardingActions.createIntegrationFailure, (state) => ({ ...state, loading: false })),
  on(OnboardingActions.createFlowDone, (state) => ({ ...state, loading: false, flow: { ...state.flow, done: true } })),
  on(OnboardingActions.getSubscriptions, (state) => ({ ...state, loading: true })),
  on(OnboardingActions.setSubscriptions, (state, action) => ({ ...state, subscriptions: action.data, loading: false })),
  on(OnboardingActions.setAlert, (state, { message, href, hrefLabel }) => ({
    ...state,
    alert: { message, href, hrefLabel },
  })),
  on(DineroActions.getOrganizations, (state) => ({ ...state, loading: true })),
  on(DineroActions.setOrganizations, (state, { organizations }) => ({ ...state, organizations, loading: false })),
);

export function reducer(state: State | undefined, action: Action) {
  return featureReducer(state, action);
}
